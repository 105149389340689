import { ceping } from '@/api'
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();
export default {
    name: 'Answer',

    created() {
        // 判断是否有id
        if (!this.$route.query.sn) {
            this.$toast('缺少订单号')
            return setTimeout(() => { this.$router.back() }, 500);
        }

        // 获取数据
        this.handleGetData()
    },

    methods: {
        // 获取数据
        handleGetData() {
            this.$toast.loading({ message: '加载中...', forbidClick: true })
            ceping.question({
                sn: this.$route.query.sn
            }).then(res => {
                this.$toast.clear()
                this.isAjax = false
                if (res.status != 1) return this.$toast(res.message)
                document.title = res.product.title
                this.product = res.product
                this.data = res.data
                this.last = res.last
                this.end = res.end
                this.sn = res.sn

                // if (!this.isLogin) {
                //     this.$dialog.confirm({
                //         title: '未登录',
                //         message: '登录后可以永久保存订单，是否登录？',
                //         confirmButtonColor: '#5ec2b5',
                //         confirmButtonText: '是',
                //         cancelButtonText: '否'
                //     }).then(() => {
                //         return this.$router.push({name: 'Login', query: {url: window.location.href}})
                //     }).catch(() => {
                //         if (this.end == 1) return this.$router.push({name: 'Report', query: {sn: this.sn, id: this.product.templates}})
                //     });
                // }

                let answer = JSON.parse(localStorage.getItem("answer"));
                if (JSON.stringify(answer) == 'null') answer = {}
                if (answer[`_${this.sn}`] == null) {
                    answer[`_${this.sn}`] = {
                        step: 0,
                        items: {}
                    }
                    localStorage.setItem("answer", JSON.stringify(answer))
                    this.items = this.data.slice(0, this.step + 1)
                } else {
                    answer = answer[`_${this.sn}`];
                    this.step = answer.step
                    this.answerItems = answer.items
                    this.items = this.data.slice(0, this.step + 1)
                }
                this.handleScrollBottom()
            }).catch(err => {
                this.$toast.clear()
                this.isAjax = false
                console.log(err)
            })
        },

        /**
         * 答题
         * @param {Object} args    答案数据
         * @param {Number} i       题目下标
         */ 
        handleAnswer (args, i) {
            let bool = this.answerItems[`_${i}`] != undefined;

            // 答案设置， 保存题目数据
            this.$set(this.answerItems, `_${i}`, args)
            if (!bool) this.step++

            // 获取缓存并设置缓存
            let answer = JSON.parse(localStorage.getItem("answer"));
            answer[`_${this.sn}`] = {
                step: this.step,
                items: this.answerItems
            }
            localStorage.setItem("answer", JSON.stringify(answer))
            if (bool) return
            setTimeout(() => {
                // 题目数据增加
                this.items = this.data.slice(0, this.step + 1)
                this.handleScrollBottom(true)
            }, 300);
        },

        // 滚动到最底部
        handleScrollBottom (bool = false) {
            this.$nextTick(() => {
                let options = { top: document.body.clientHeight, left: 0 };
                if (bool) options.behavior = 'smooth'
                window.scrollTo(options);
                // 所有动作执行完判断是否需要提交
                this.handleSubmit(); 
            })
        },

        // 询问跳转
        handleCheck () {
            this.$dialog.confirm({
                title: '提示',
                message: '答题结束，是否查看报告？',
                confirmButtonColor: '#5ec2b5',
                confirmButtonText: '查看报告',
                cancelButtonText: '返回测评'
            }).then(() => {
                this.$router.push({name: 'Report', query: {sn: this.sn, id: this.product.templates}})
            }).catch(() => {
                this.$router.push({name: 'Ceping'})
            });
        },

        // 测试提交接口
        handleSubmit () {
            // 已经提交，询问跳转
            if (this.end == 1) return this.handleCheck();

            if (this.step < this.data.length) return;

            if (this.isAjax) return
            this.$toast.loading({ message: '答题提交...', forbidClick: true })
            this.isAjax = true
            let str = [], options = {};
            this.items.map((v, i) => {
                let obj = this.answerItems[`_${i}`];
                str[i] = {}
                str[i]['qid'] =  obj.qid
                str[i]['aid'] =  obj.aid
            })
            // str = str.substring(1);
            options = { sn: this.sn, answers: str };
            ceping.answer(options).then(res => {
                this.$toast.clear()
                this.isAjax = false

                // 删除缓存数据
                let answer = JSON.parse(localStorage.getItem("answer"));
                delete answer[`_${this.sn}`]
                localStorage.setItem("answer", JSON.stringify(answer))

                this.handleCheck()
            }).catch(err => {
                this.$toast.clear()
                this.isAjax = false
                console.log(err)
            })
        }
    },

    data() {
        return {
            isAjax: false,
            isQrcode: false, // 展示客服二维码
            isLogin: _global.isLogin,
            userInfo: _global.userInfo, // 用户数据
            letter: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'M'],
            qrcodeUrl: '',  // 客服二维码
            product: {},
            data: [],       // 测试数据
            items: [],      // 测试数据
            last: 0,        // 对应的qid，如果为0时表示未答过提
            end: 0,         // 字段为1的时候表示该测评已经答完所有题，可以直接提示用户调用
            sn: '',         // 订单号

            timer: null,

            step: 0,        // 答题位置
            answerItems: {},// 答案
        }
    }

}